<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Sent Date"
            label-for="sent_date"
          >
            <div class="position-relative">
              <flat-pickr
                v-model="filters['created_at']"
                class="form-control"
                :config="{ mode: 'range',enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y:m:d H:i:s'}"
                placeholder="Search Date"
              />
              <div
                v-if="filters['created_at']"
                class="flatpickr-cancel"
                @click="clearDate('created_at')"
              >
                <feather-icon icon="XIcon" />
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Apply and Clear Filters -->
      <b-row class="mt-1">
        <b-col>
          <filter-buttons
            :filter-data="filters"
            :apply-filters="applyFilters"
            :clear-filters="clearFilters"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card
      no-body
    >
      <div>
        <div
          class="mx-2 mt-2 mb-1"
        >
          <b-row class="d-sm-flex align-items-start">
            <b-col
              class="d-block d-sm-flex flex-wrap justify-content-end align-items-center mb-1 mb-md-0"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.v-primary="'Mark All As Read'"
                variant="outline-primary"
                class="mr-1"
                size="sm"
                @click="onMarkAllAsRead()"
              >
                <mdicon
                  name="CheckAll"
                  size="15"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.v-primary="'Clear All'"
                variant="outline-danger"
                class="mr-1"
                size="sm"
                @click="onClickDeleteNotification({})"
              >
                <mdicon
                  name="CloseBox"
                  size="15"
                />
              </b-button>
              <ReportTableButtons
                :items="rows"
                :json_fieldz="json_fields"
                :name="`Notifications - Page ${page}.xls`"
                :bulkname="`Notifications.xls`"
                :fetch="getNotificationListNoPagination"
                @refresh="onClickRefresh"
              />
            </b-col>
          </b-row>
        </div>

        <!-- table -->
        <b-overlay
          id="overlay-background"
          variant="light"
          opacity="0.30"
          blur="10px"
          rounded="sm"
        >
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="rows"
            :fields="fields"
            show-empty
            sticky-header="100vh"
            class="hide-vertical-scroll"
            @sort-changed="sortChanged"
          >
            <template #empty>
              <TableDataFetching
                :rows="rows"
                :data-loading="dataLoading"
              />
            </template>
            <template #cell(message)="data">
              <div class="d-flex align-items-start">
                {{ data.value.length > 50 ?data.value.substring(0, 50)+'...' : data.value }}
              </div>
            </template>
            <template #cell(type)="data">
              <div class="d-flex align-items-start">
                <div class="width-75">
                  <b-badge variant="light-success">
                    {{ data.value.toString().split('\\').reverse()[0].replace(/([a-z0-9])([A-Z])/g, '$1 $2').split(' ')[0] }}
                  </b-badge>
                </div>
              </div>
            </template>
            <template #cell(is_read)="data">
              <div v-if="data.value">
                <b-badge variant="light-success">
                  True
                </b-badge>
              </div>
              <div v-else>
                <b-badge variant="light-danger">
                  False
                </b-badge>
              </div>
            </template>
            <template #cell(created)="data">
              <div>
                {{ __dateTimeFormatter(data.value) }}
              </div>
            </template>
            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="12"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  @click="onClickViewNotification(data.item)"
                >
                  <feather-icon icon="EyeIcon" />
                  View
                </b-dropdown-item>
                <b-dropdown-item
                  @click="onClickDeleteNotification(data.item)"
                >
                  <feather-icon icon="XIcon" />
                  Delete
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row
              cols="12"
            >
              <b-col
                class="d-flex align-items-center justify-content-sm-start"
                md="3"
              >
                <label class="width-75">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block width-100"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                class="d-flex align-items-center justify-content-sm-end"
                md="9"
              >
                <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="(meta.total)? meta.total : 0"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </div>
    </b-card>
    <view-notification-modal ref="view_notification_modal" />
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BPagination,
  BRow,
  BOverlay,
  VBModal,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { avatarText } from '@core/utils/filter'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import store from '@/store/index'
// eslint-disable-next-line import/extensions
import ViewNotificationModal from '@/components/Notification/ViewNotificationModal'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const notificationRepository = RepositoryFactory.get('notification')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BButton,
    BPagination,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    vSelect,
    flatPickr,
    BDropdown,
    BDropdownItem,
    BCardActions,
    BBadge,
    ViewNotificationModal,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      // Filters Start
      filters: {},
      options: {
        delimiter: {
          delimiter: ' to ',
          blocks: [4, 4],
          uppercase: true,
        },
      },
      // Filters End
      avatarText,
      total: 0,
      meta: {},
      dataLoading: false,
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      fields: [
        {
          key: 'message',
          label: 'Message',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'type',
          label: 'Notification Type',
          sortable: true,
        },
        {
          key: 'is_read',
          label: 'Read',
          sortable: true,
        },
        {
          key: 'created',
          label: 'Date',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
        },
      ],
      rows: [],
      /* eslint-disable no-useless-escape */
      notificationTypes: [
        {
          key: 'OrderReminderNotification',
          name: 'Status Notification',
        },
        {
          key: 'FinanceStatusNotification',
          name: 'Finance Notification',
        },
        {
          key: 'OtherNotification',
          name: 'Other Notification',
        },
      ],
      json_fields: {
        Message: 'message',
        Type: 'type',
        'Is Read': 'is_read',
        Sent: 'created',
      },
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    page() {
      this.getNotificationList()
    },
    perPage() {
      this.getNotificationList()
    },
  },
  async mounted() {
    this.totalRows = this.rows.length
    this.getNotificationList()
  },

  methods: {
    async getNotificationList() {
      this.dataLoading = true
      this.rows = []
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await notificationRepository.getPickupRequestNotifications(this.page, this.filterQuery, this.perPage))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async getNotificationListNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await notificationRepository.getAllNotificationsNoPagination(this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async onMarkAllAsRead() {
      try {
        const res = await notificationRepository.markAsReadAll()
        if (res.status === 200) {
          this.showSuccessMessage('Updated Successfully')
          this.onClickRefresh()
        } else {
          this.showErrorMessage("Couldn't update notification data")
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async onClickDeleteNotification(notification) {
      try {
        const payload = {
          notification_id: notification.id || 'all',
        }
        const res = await notificationRepository.deleteNotification(payload)
        if (res.status === 200) {
          this.showSuccessMessage('Notification Deleted Successfully')
          this.onClickRefresh()
        } else {
          this.showErrorMessage("Couldn't delete notification")
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    onClickViewNotification(notification) {
      this.$refs.view_notification_modal.openModal(notification, 'View Notification')
    },
    // REQUIRED
    applyFilters() {
      this.getNotificationList()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.getNotificationList()
      })
    },
    handleChangePage(page) {
      this.page = page
      this.getNotificationList()
    },
    changePage(value) {
      this.perPage = value
    },
    // REQUIRED
    sortChanged(e) {
      const key = e.sortBy
      if (key) {
        const active = e.sortDesc ? '-' : ''
        this.sort = active + key
        this.getNotificationList()
      }
    },
    onClickRefresh() {
      this.getNotificationList()
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
@import '../@core/scss/vue/libs/vue-flatpicker.scss';
</style>
